import {useRef, memo} from "react";
import Portal from "../../portal";
import {Popper} from 'react-popper'
import ClickOutside from "../ClickOutside";

const Popover = ({isOpened,onClose,reference,placement,children,popoverStyle={}, ...rest}) => {
    const popperRef = useRef()
    if(!isOpened){
        return <></>
    }
    return  <Portal>
                <ClickOutside reference={reference} onClickOutside={onClose}>
                    <Popper
                            innerRef={popperRef}
                            referenceElement={reference}
                            placement={placement}
                            {...rest}
                            >
                            {({ref, style})=>(
                                <div ref={ref} style={{...style, ...popoverStyle}} className={'popover'}>
                                    {children}
                                </div>
                            )}
                    </Popper>
                </ClickOutside>
            </Portal>
}
export default memo(Popover)
