import React, {useEffect, useRef, useState} from 'react';
import Moveable, {makeAble} from "react-moveable";
import PropTypes from "prop-types";
import ColorPickerPopup from "./ColorPickerPopup";
import Popover from "../../../UI/Popover";
import {getShapeMarkerTransform, updateSvgColors} from "../utils";
import {useShapes} from "../index";
import {MoveableManagerInterface} from "react-moveable";
import {Renderer} from "react-moveable";
import {CustomRotation} from "../elements/MovableElements";


const ShapeMarker = ({svg, keepRatio,map, transform, onTransform, onDelete,isEditMode, onChangeColor, fillColor, strokeColor, uuid}) => {
    const targetRef = React.useRef(null);
    const popperRef = useRef()
    const {shapeModeIsActive, onSetShaperClickState} = useShapes();
    const isActive = shapeModeIsActive === uuid;
    console.log("shapeModeIsActive", shapeModeIsActive, uuid, isActive);
    const [editMode,setEditMode] = useState(isEditMode)
    const [colorMode,setColorMode] = useState()
    const [fillColorState, setFillColorState] = useState(fillColor);
    const [strokeColorState, setStrokeColorState] = useState(strokeColor);
    const [isDragging, setIsDragging] = useState(false);
    const [isZooming, setIsZooming] = useState(false);

    useEffect(() => {
        setEditMode(isActive)
    }, [isActive]);


    useEffect(() => {
        if (map) {
            map.on('zoomstart', () => {
                setEditMode(false)
                setColorMode(false)
            });

            // map.on("zoomend", () => {
            //    // targetRef.current.click();
            //    // if (shapeModeIsActive) {
            //    //     setEditMode(true)
            //    // }
            // })
        }
    }, [map, shapeModeIsActive])

    // const transformWithScale = applyScaleToTransform(transform, mapScale);
    const transformWithScale = transform;

    const handleTransform = (e) => {
        if (!shapeModeIsActive) {
            return;
        }
        // e.target.style.transform = getShapeMarkerTransform(e, targetRef.current, isEditMode);
        e.target.style.transform = e.drag.transform;
        onTransform?.(uuid, e.drag.transform);
        // Call the onTransform callback if exists
        // onTransform?.(uuid, e.drag.transform, getShapeMarkerTransform(e, targetRef.current, isEditMode));
        setColorMode(false);
    };


    const clickHandler = (e) => {
        // if (isDragging) {
        //     return;
        // }
        e?.preventDefault();
        e?.stopPropagation();
        console.log("click event");
        onSetShaperClickState(uuid)
        if (isEditMode || !shapeModeIsActive){
            return;
        }
        if (colorMode) {
            setColorMode(false)
            return;
        }

        setColorMode(!!editMode)

        setTimeout(() => {
            setEditMode(true)
        },200)
    }

    console.log("is acitve shape", shapeModeIsActive);

    const handleDelete = () => {
        setColorMode(false)
        onDelete?.(uuid)
    }

    const updateColorHandler = (color, type) => {
        if (type === "fill") {
            setFillColorState(color)
            onChangeColor?.(uuid, color, "fillColor")
        } else {
            setStrokeColorState(color)
            onChangeColor?.(uuid, color, "strokeColor")
        }
    }

    const touchMoveHandler = (e) => {
        setEditMode(true);
        setColorMode(false)
    }

    const mouseMoveHandler = (e) => {
        if (!!e.pressure) {
            setColorMode(false)
            // setEditMode(false)
        }
    }

    console.log("isDragging",colorMode, isDragging);

    const closeColorMode = () => {
        setColorMode(false)
    }


    const handleMouseEnd = (e) => {
        // console.log("e", e);
        //     setIsDragging(false);
    }

    const mouseDownHandler = (e) => {
        // e.stopPropagation();
        console.log("event down", e);
        e.preventDefault();
        onSetShaperClickState(uuid)

    }
    console.log("isZooming", isZooming);
    return (
         <div className="shape-marker--root" style={{position:"relative"}} >
             <Popover isOpened={colorMode}
                      onClose={closeColorMode}
                      reference={targetRef.current}
                      innerRef={popperRef}
                      placement={'top-start'}
                      modifiers={[{
                          name: 'offset',
                          options: {
                              offset: [2, 42],
                          },
                      },]}
                      popoverStyle={{zIndex:2000}}>
                 <ColorPickerPopup handleClose={clickHandler} handleDelete={handleDelete} onChangeColor={updateColorHandler} fillColor={fillColorState} strokeColor={strokeColorState}/>
             </Popover>
            <div  className="shape-marker--container" onPointerMove={mouseMoveHandler}  onMouseUp={handleMouseEnd}
                   onTouchEnd={handleMouseEnd} onClick={clickHandler} onMouseDown={mouseDownHandler} onTouchMove={touchMoveHandler} onTouchStart={clickHandler}>
                <div  className="shape-marker--target" ref={targetRef} style={{
                    transform: transformWithScale || "translate(0px, 0px) rotate(0deg)",
                    // width:75,
                    // height:75,
                }}>
                    <div style={{
                        width:"100%",
                        height:"100%",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center",
                    }} dangerouslySetInnerHTML={{__html: updateSvgColors(svg, fillColorState, strokeColorState)}}/>
                </div>
                <Moveable
                 target={targetRef}
                 rotatable={editMode && shapeModeIsActive}
                 throttleRotate={0}
                    // rotationPosition="bottom-right"
                 transformOrigin="center center"
                    pinchable={true}
                    zoom={1.3}
                 origin={false}
                      useAccuratePosition={false}
                 rotationTarget={".moveable-custom-rotation"}
                 rotationPosition={"none"}
                 originDraggable={true}
                 linePadding={0}
                    ables={[CustomRotation]}
                props={{
                    customRotation: editMode,
                }}
                 hideDefaultLines={!editMode && !colorMode}
                 edge={true}
                 padding={{left: 0, top: 0, right: 0, bottom: 0}}
                 onRotate={handleTransform}
                    onScaleStart={({ set, dragStart,setMinScaleSize, setFixedDirection }) => {
                        setMinScaleSize([30, 30]);
                        // set(frame.scale);
                        setFixedDirection([0, 0]);
                        // dragStart && dragStart.set(frame.translate);
                    }}
                    scalable={editMode && shapeModeIsActive}
                    keepRatio={keepRatio}
                    throttleScale={0}
                    // renderDirections={["nw","n","ne","w","e","sw","s","se"]}
                    onScale={handleTransform}
                    // onScale={e => {
                    //     console.log("e.target", e.target);
                    //     e.target.style.transform = e.drag.transform;
                    //                         }}
             />
         </div>
      </div>
    );
};


ShapeMarker.propTypes = {
    keepRatio: PropTypes.bool,
    svg: PropTypes.string,
    onTransform: PropTypes.func,
    transfrom: PropTypes.string,
    uuid: PropTypes.string,
    id: PropTypes.string,
    onDelete: PropTypes.func,

};

export default ShapeMarker;
