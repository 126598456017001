import {useDispatch, useSelector} from "react-redux";
import {setActiveShape, setShapeMenuIsOpen, setShapes} from "../../../store/actions/shapeTool";
import {useCallback, useEffect} from "react";
import {setShaperClickState} from "../../../store/actions/painterStart";

const useShapes = () => {
    const dispatch = useDispatch()
    const shapes = useSelector(state => state.shapes.shapes)
    const activeShape = useSelector(state => state.shapes.activeShape)
    const menuIsOpen = useSelector(state => state.shapes.shapeMenuIsOpen)
    const shapeModeIsActive = useSelector(state => state.painterStart.shapeClickedState)
    // const painterStart = useSelector(state => state.painterStart.data)

    const onSetActiveShape = (item) => {
        dispatch(setActiveShape(item))
    }
    const onSetShapes = (data) => {
        dispatch(setShapes(data))
    }
    const onSetMenuIsOpen = (data) => {
        dispatch(setShapeMenuIsOpen(data))
    }

    const pushShape = useCallback((item) => {
        onSetShapes([...shapes, item])
    },[])

    const removeShape = useCallback((id) => {
        onSetShapes(shapes.filter(item => item.id !== id))
    },[])

    const updateShape = useCallback((item) => {
        onSetShapes(shapes.map(shape => shape.id === item.id ? item : shape))
    },[])

    const onSetShaperClickState = useCallback((data) => {
        dispatch(setShaperClickState(data))
    },[])

    const initialize = useCallback((data) => {
        onSetShapes(data.shapes);
        onSetActiveShape(data.activeShape);
        onSetMenuIsOpen(data.menuIsOpen);
        onSetShaperClickState(data.shapeModeIsActive);

    },[])
    //
    // useEffect(() => {
    //     if (painterStart) {
    //         // onSetShaperClickState(false)
    //     }
    // }, [painterStart, onSetShaperClickState]);


    return {
        shapes,
        activeShape,
        menuIsOpen,

        onSetActiveShape,
        onSetShapes,
        onSetMenuIsOpen,
        onSetShaperClickState,

        pushShape,
        removeShape,
        updateShape,

        initialize,

        shapeModeIsActive
    }
}

export default useShapes
