import useShapes from "../useShapes";
import {getRandomNumber, getSvgChildrenDimensions, svgToURL} from "../utils";
import mapboxgl from "mapbox-gl";
import {useCallback, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import ShapeMarker from "./ShapeMarker";
import {store} from "../../../../store";
import {Provider} from "react-redux";
import {allShapes} from "../mock-data";
import GroupShapeMarker from "./GroupShapeMarker";
import {debounce} from "lodash";

const shapesSizeMap = {

}

const ShapeToolEditor = ({map}) => {
  const { onSetShapes, shapes,shapeModeIsActive,onSetShaperClickState, onSetActiveShape} = useShapes();
    const markersRef = useRef({});
    const shapeModeIsActiveRef = useRef(shapeModeIsActive);
    const shapesRef = useRef(shapes);
    const desiredSizeInMeters =  5000;

    useEffect(() => {
        shapeModeIsActiveRef.current = shapeModeIsActive
    },[shapeModeIsActive])

    useEffect(() => {
        shapesRef.current = shapes
    },[shapes])

    // useEffect(() => {
    //     document.addEventListener('click', (e) => {
    //         console.log("e.target.classList", e,e.target.classList.contains('shape-marker'));
    //         if (!e.target.classList.contains('shape-marker')) {
    //             // onSetActiveShape(null)
    //         }
    //     })
    // }, []);
    //
    // useEffect(() => {
    //     document.querySelector('.mapboxgl-canvas').addEventListener('click', (e) => {
    //         console.log("e.target.classList", e,e.target.classList.contains('shape-marker'));
    //         onSetShaperClickState(false)
    //         // if (!e.target.classList.contains('shape-marker')) {
    //         //     // onSetActiveShape(null)
    //         // }
    //     })
    // }, []);

    const handleShapeChange = useCallback((uuid, transform) => {
        console.log("transsform", uuid, transform);
        onSetShapes(shapesRef.current.map(shape => {
            if (shape.uuid === uuid) {
                return {
                    ...shape,
                    transform
                };
            }
            return shape;
        }))

    },[shapesRef, onSetShapes]);


    const handleChangeColor = useCallback((uuid, color, type) => {
        console.log("shapesRef.current", shapesRef.current,  color, type, uuid);
        onSetShapes(shapesRef.current.map(shape => {
            if (shape.uuid === uuid) {
                return {
                    ...shape,
                   colors:{
                       ...shape.colors,
                       [type]:color
                   }
                };
            }
            return shape;
        }))

    },[shapesRef, onSetShapes]);

    // console.log("SHAPES RENDERER ----> ", shapes);

    const calculatePixelSizeFromMeters = (marker, meters) => {
        const customElement = marker.getElement();
        const centerCoordinates = [marker.getLngLat().lng, marker.getLngLat().lat];
        const centerPixel = map.project(centerCoordinates);
        const northCoordinates = [centerCoordinates[0], centerCoordinates[1] + meters / 111320]; // Roughly 1 meter in latitude
        const northPixel = map.project(northCoordinates);
        return Math.abs(northPixel.y - centerPixel.y);
    };

    // Update custom element size on zoom
    const updateElementSize = (marker, desiredSizeInMeters) => {
        // console.log("marker", marker);
        const customElement = marker?.getElement?.() || marker._element;
        // console.log(customElement, customElement.width, customElement.height, desiredSizeInMeters);
        // const svgString = customElement.getElementsByTagName('svg')[0].innerHTML;
        // console.log("svgString", svgString);
        const pixelSize = calculatePixelSizeFromMeters(marker, desiredSizeInMeters);

        // if (shapesSizeMap)



        const {width, height} = shapesSizeMap[customElement.dataset.shapeId] || {width: 100, height: 100};

        customElement.style.width = `${pixelSize * width || 100}px`;
        customElement.style.height = `${pixelSize * height || 100}px`;
    };


    const deleteHandler = useCallback((uuid)=> {
        console.log("uuid", uuid, shapes, shapes.filter(shape => shape.uuid !== uuid));
        onSetShapes(shapesRef.current.filter(shape => shape.uuid !== uuid))
    },[onSetShapes, shapesRef.current])

    const calculateMarkersScale = () => {
        Object.keys(markersRef.current).forEach(uuid => {
            // Assuming 'marker' is an instance of Mapbox Marker
            const marker = markersRef.current[uuid];
            // Initial size update
            updateElementSize(marker, desiredSizeInMeters);

        });
    }

    useEffect(() => {
        // Create a set of UUIDs from the shapes and markers
        const shapeUUIDs = new Set(shapes.map(shape => shape.uuid));
        map.doubleClickZoom.disable()

        // Remove markers for shapes that have been deleted
        Object.keys(markersRef.current).forEach(uuid => {
            if (!shapeUUIDs.has(uuid)) {
                // Remove the marker from the map
                markersRef.current[uuid].remove();
                // Delete the marker from the ref
                delete markersRef.current[uuid];
            }
        });

        // const debeounced400 = debounce((fn) => fn(), 400);

        // map.on('zoomend', debounce(calculateMarkersScale, 10));
        map.on('zoomend', calculateMarkersScale);

        shapes.forEach((shapeMarker) => {

            if (markersRef.current[shapeMarker.uuid]) {

                if (!shapeModeIsActiveRef.current) {
                    markersRef.current[shapeMarker.uuid].setDraggable(false);
                    return;
                } else {
                    markersRef.current[shapeMarker.uuid].setDraggable(true);
                }
                // If the marker already exists for this shape, skip to the next shape
                return;
            }

            const markerNode = document.createElement('div');
            markerNode.className = 'shape-marker';
            markerNode.setAttribute('data-shape-id', shapeMarker.id);

            markerNode.setAttribute('id', shapeMarker.uuid);
            const svg = allShapes[shapeMarker.category]?.find?.(shape => shape.id === shapeMarker.id).svg || shapeMarker.svg
            let sizesObj = {width: 0, height: 0}

            if (shapesSizeMap[shapeMarker.id]) {
                sizesObj = shapesSizeMap[shapeMarker.id]
            } else {
                sizesObj = getSvgChildrenDimensions(svg)
                shapesSizeMap[shapeMarker.id] = sizesObj
            }
            let {width, height} = sizesObj;


            console.log("sizes", {width, height});
            markerNode.style.width = `${width}px`;
            markerNode.style.height = `${height}px`;
            ReactDOM.render(
                <Provider store={store}>
                    <ShapeMarker
                        transform={shapeMarker.transform}
                        onDelete={deleteHandler}
                        svg={svg}
                        keepRatio={shapeMarker.keepRatio}
                        id={shapeMarker.id}
                        map={map}
                        uuid={shapeMarker.uuid}
                        fillColor={shapeMarker?.colors?.fillColor}
                        strokeColor={shapeMarker?.colors?.strokeColor}
                        onTransform={handleShapeChange}
                        onChangeColor={handleChangeColor}
                        // isEditMode={shapeModeIsActive}
                    />
                </Provider>,
                markerNode
            );

            console.log("shapeModeIsActiveRef", shapeModeIsActiveRef.current);

            const marker = new mapboxgl.Marker({ draggable: true, element: markerNode, rotationAlignment: "map",
                pitchAlignment: "map", })
                .setLngLat([shapeMarker.lng || map.getCenter().lng + getRandomNumber(1.02, 3.02), shapeMarker.lat || map.getCenter().lat + getRandomNumber(1.02, 3.02)])  // Update this to position based on shape data
                .addTo(map);

            // Add dragging behavior
            function onDragEnd() {
                const lngLat = marker.getLngLat();
                onSetShapes(shapesRef.current.map(shape => {
                    if (shape.uuid === markerNode.id) {
                        return {
                            ...shape,
                            lat: lngLat.lat,
                            lng: lngLat.lng
                        };
                    }
                    return shape;
                }));
            }
            marker.on?.('dragend', onDragEnd);
            console.log("shapes", shapes);
            updateElementSize(marker, desiredSizeInMeters);

            // Save the marker instance in the ref
            markersRef.current[shapeMarker.uuid] = marker;

        });
        // Note: no cleanup function is necessary because markers are manually managed
        // Returning a cleanup function would be necessary if we added, removed,
        // or changed listeners that needed cleanup.
        console.log("shapeModeIsActive", shapeModeIsActive);
        return () => {
            map.doubleClickZoom.enable();
        }
    }, [shapes, deleteHandler, map,shapeModeIsActiveRef.current, onSetShapes]);



    return null;
};

export default ShapeToolEditor
