import * as React from "react";
import Moveable, { makeAble, MoveableManagerInterface, Renderer } from "react-moveable";
import RotateIcon from "../../../../assets/imgs/rotate-arrows.svg";

export const CustomRotation = makeAble("customRotation", {
    render(moveable: MoveableManagerInterface<any, any>, React: Renderer): any {
        const rect = moveable.getRect();
        console.log("moveable", moveable);
        const { pos1, pos2 } = moveable.state;

        // Add key (required)
        // Add class prefix moveable-(required)
        return <div key={"custom-rotation"} className={"moveable-custom-rotation"} style={{
            position: "absolute",
            transform: `translate(${pos2[0]}px, ${pos2[1]}px) rotate(${rect.rotation}deg) translate(0px, -22px)`,
            width: "22px",
            height: "22px",
            cursor: "move",
            left: 0,
            top: 0,
            transformOrigin: "0px 0px",
            willChange: "transform",
        }}>
            <img src={RotateIcon} width={22} height={22} alt="" style={{rotate:"-90deg", pointerEvents:"none"}}/>
        </div>;
    },
});
