import React, {memo, useCallback, useEffect} from "react";
import { setPainterGeoJsonDataST } from "../../../../store/actions/mapStateAction";
import {
  setCommentToolboxState,
  setDrawerToolboxClickedState,
  setEraserClickedState,
  setLineDrawToolboxState, setPolygonDrawToolboxState,
  setRulerClickedState,
  setShaperClickState, setStickyNotesClickedState,
} from "../../../../store/actions/painterStart";
import { connect } from "react-redux";
import ShapeIcon from "../../../../assets/imgs/PaintBar/shape-icon.svg";
import { getShapeClickedState } from "../../../../store/selectors";
import {ShapeSelector, ShapeToolEditor, useShapes} from "../index";
import {createPortal} from "react-dom";


const ShapeToolButton = ({
  getShapeClickedState,
  setShaperClickState,
  setStartPaintClickState,
  setLineDrawToolboxState,
  setCommentToolboxState,
  setEraserClickedState,
  setStickyNotesClickedState,
  setPolygonDrawToolboxState,
  setDrawerToolboxClickedState,
  setRulerClickedState,
    map
}) => {
  const {onSetMenuIsOpen,shapeModeIsActive, menuIsOpen} = useShapes();

  const mapNode = document.getElementById("map");
  const container = document.getElementById('shape_editor--container')

  const onShapeHandle = useCallback(() => {
    // setStartPaintClickState(false);
    setCommentToolboxState(false);
    setEraserClickedState(false);
    setRulerClickedState(false);
    setShaperClickState(true);

    setDrawerToolboxClickedState(false);
    setPolygonDrawToolboxState(false);
    setStickyNotesClickedState(false);
    setLineDrawToolboxState(false);

    onSetMenuIsOpen(!menuIsOpen);
    console.log("sxs");
  }, [getShapeClickedState, menuIsOpen, onSetMenuIsOpen, setCommentToolboxState, setEraserClickedState, setLineDrawToolboxState, setRulerClickedState, setShaperClickState, setStartPaintClickState]);

  useEffect(() => {
    if (!document.querySelectorAll('.pain_items')) return;
    console.log(document.querySelectorAll('.pain_items'));
    document.querySelectorAll('.pain_items').forEach((item) => {
      item.addEventListener('click', (e) => {

        console.info("item.attrs.id",e, item, item.id);
        if (item.id !== 'shape_icon') {
          setShaperClickState(false);
          onSetMenuIsOpen(false)
        } else {
          return;
          onShapeHandle();
        }
      });
    })
  }, []);

  useEffect(() => {
   if (shapeModeIsActive) {
     setCommentToolboxState(false);
     setEraserClickedState(false);
     setRulerClickedState(false);
     setDrawerToolboxClickedState(false);
     setPolygonDrawToolboxState(false);
     setStickyNotesClickedState(false);
     setLineDrawToolboxState(false);
   }
  }, [shapeModeIsActive]);

  useEffect(() => {
    document.querySelector(".mapboxgl-canvas").addEventListener("click", (e) => {
        setShaperClickState(false);
    })

       document.getElementById("paint-bar-id").addEventListener("click", (e) => {
        setShaperClickState(false);
    })

    mapNode.append( createContainer())


  },[])

  const createContainer = () => {
    const container = document.createElement("div");
    container.id = "shape_editor--container";

    return container
  }


  const toggleMenuOpen = () => {
    onSetMenuIsOpen(!menuIsOpen);
  }

  return (
    <>
      {mapNode && createPortal(
          <ShapeToolEditor map={map}/>,
          mapNode
      )}

      <div
        id="shape_icon"
        className={`pain_items ${getShapeClickedState ? "button_active" : ""}`}
        onClick={onShapeHandle}
      >
        <img src={ShapeIcon} alt="" className="icon_img" />
      </div>
      {menuIsOpen && (
        <div
          className="shape_tools_container"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ShapeSelector map={map}/>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getShapeClickedState: getShapeClickedState(state),
});
const mapDispatchToProps = {
  setCommentToolboxState: setCommentToolboxState,
  setEraserClickedState: setEraserClickedState,
  setDrawerToolboxClickedState: setDrawerToolboxClickedState,
  setPainterGeoJsonDataST: setPainterGeoJsonDataST,
  setLineDrawToolboxState: setLineDrawToolboxState,
  setRulerClickedState: setRulerClickedState,
  setShaperClickState: setShaperClickState,
  setStickyNotesClickedState: setStickyNotesClickedState,
  setPolygonDrawToolboxState: setPolygonDrawToolboxState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(ShapeToolButton));
