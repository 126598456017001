import React, { memo, useState } from 'react';
import ColorCircle from "../../components/ColorCircle";
import CloseIcon from '../../../../assets/imgs/PaintBar/close-icon.svg';
import DeleteIcon from '../../../../assets/imgs/PaintBar/delete-trash-icon.svg';
import { color_picker_data } from "../../../../utils/color_picker_data";
import "./color-picker-styles.css";
import {rgbaToHexAndOpacity} from "../utils";

const SHAPE_DEFAULT_COLOR = "#95d4f3"

const ColorPickerPopup = ({ handleClose, handleDelete, onChangeColor, fillColor, strokeColor }) => {
    const {hex:fillHex,opacity:fillOpacity} = rgbaToHexAndOpacity(fillColor);
    const {hex:strokeHex,opacity:strokeOpacity} = rgbaToHexAndOpacity(strokeColor);
    console.log("fillHex",fillColor, fillHex);
    console.log("strokeHex",strokeColor, strokeHex);
    const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
    const [isOpenColors, setIsOpenColors] = useState(false);
    const [isFill, setIsFill] = useState(true);
    const [currentFillColor, setCurrentFillColor] = useState(fillHex || SHAPE_DEFAULT_COLOR); // Default fill color
    const [currentStrokeColor, setCurrentStrokeColor] = useState(strokeHex || SHAPE_DEFAULT_COLOR); // Default stroke color
    const [currentFillOpacity, setCurrentFillOpacity] = useState(fillOpacity); // Fill opacity
    const [currentStrokeOpacity, setCurrentStrokeOpacity] = useState(strokeOpacity); // Stroke opacity
    const [colorPickerState, setColorPickerState] = useState(false); // Color picker state
    const colors = [ SHAPE_DEFAULT_COLOR, '#FF5733', '#33FF57', '#3357FF', '#F3FF33', '#FF33A1']; // Example colors
    const targetRef = React.useRef(null);
    console.log("currentFillOpacity", currentFillOpacity);
    console.log("currentStrokeOpacity", currentStrokeOpacity);
    console.log("value opacity", isFill ? currentFillOpacity * 100 : currentStrokeOpacity * 100);
    const handleRangeChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const value = event.target.value / 100;
        let colorWithOpacity;

        if (isFill) {
            setCurrentFillOpacity(value);
            colorWithOpacity = `rgba(${parseInt(currentFillColor.slice(1, 3), 16)},${parseInt(currentFillColor.slice(3, 5), 16)},${parseInt(currentFillColor.slice(5, 7), 16)},${value})`;
        } else {
            setCurrentStrokeOpacity(value);
            colorWithOpacity = `rgba(${parseInt(currentStrokeColor.slice(1, 3), 16)},${parseInt(currentStrokeColor.slice(3, 5), 16)},${parseInt(currentStrokeColor.slice(5, 7), 16)},${value})`;
        }
        onChangeColor?.(colorWithOpacity, isFill ? 'fill' : 'stroke');
    };

    const toggleColorsDropdown = () => {
        setColorPickerState(!colorPickerState);
        setIsOpenColors(!isOpenColors);
    };

    const colorSelectHandler = (e, value) => {
        e.preventDefault();
        e.stopPropagation();
        let colorWithOpacity;
        console.log("value", value);
        if (isFill) {
            colorWithOpacity = `rgba(${parseInt(value.slice(1, 3), 16)},${parseInt(value.slice(3, 5), 16)},${parseInt(value.slice(5, 7), 16)},${currentFillOpacity})`;
            console.log("colorWithOpacity", colorWithOpacity);
            setCurrentFillColor(value);
            // setCurrentFillOpacity(1)
        } else {
            colorWithOpacity = `rgba(${parseInt(value.slice(1, 3), 16)},${parseInt(value.slice(3, 5), 16)},${parseInt(value.slice(5, 7), 16)},${currentStrokeOpacity})`;
            setCurrentStrokeColor(value);

            // setCurrentStrokeOpacity(1)
        }

        onChangeColor?.(colorWithOpacity, isFill ? 'fill' : 'stroke');
    };

    return (
        <div className="color-picker-popup-container">
            <div className='color-popup-container_color-picker' onClick={toggleColorsDropdown}>
                <span className={"current-picked-color"}>
                    <ColorCircle color={{ color: isFill ? currentFillColor : currentStrokeColor }} status={true} />
                </span>
            </div>
            <div className='color-popup-container_actions'>
                <span className={'delete-icon-wrapper'} onClick={() => handleDelete?.()}>
                    <img className={'delete-icon'} src={DeleteIcon} alt="Delete" />
                </span>
                <span className={'close-icon-wrapper'} onClick={handleClose}>
                    <img className={'close-icon'} src={CloseIcon} alt="Close" />
                </span>
            </div>
            {isOpenColors && isOpenColorPicker && (
                <div className="color-picker-container">
                    {color_picker_data.map((color, index) => (
                        <div
                            key={index}
                            className="color-picker-item"
                            style={{ backgroundColor: color }}
                            onClick={(e) => colorSelectHandler(e, color)}
                        ></div>
                    ))}
                    <span></span>
                </div>
            )}
            {isOpenColors && (
                <div className={'color-and-opacity-picker-popup'}>
                    <div className={'fill-stroke-buttons'}>
                        <div className={`fill-stroke-button ${!isFill && 'active'}`}
                             onClick={() => setIsFill(false)}>Եզրագիծ</div>
                        <div className={`fill-stroke-button ${isFill && 'active'}`}
                             onClick={() => setIsFill(true)}>Լցվածություն</div>
                    </div>
                    <div className={'opacity-selector'}>
                        <div className="range-container">
                            <input
                                className="custom-slider"
                                type="range"
                                min="0"
                                max="100"
                                value={isFill ? currentFillOpacity * 100 : currentStrokeOpacity * 100}
                                onChange={handleRangeChange}
                            />
                            <div className="checkerboard"/>
                            <div className={"background"} style={{background: `linear-gradient(to right, transparent, ${isFill ? currentFillColor : currentStrokeColor})`}}/>
                        </div>
                    </div>
                    <div className={'colors-row'}>
                        {colors.map((color, index) => (
                            <div key={index} className="color-picker-item" onClick={(e) => colorSelectHandler(e, color)}>
                                <ColorCircle color={{ color }} status={color === (isFill ? currentFillColor : currentStrokeColor)} />
                            </div>
                        ))}
                        <div
                            className={"available-color"}
                            onClick={() => setIsOpenColorPicker(!isOpenColorPicker)}>
                            <svg
                                style={{
                                    background: "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                    borderRadius: "50%",
                                    height: 25,
                                    width: 25,
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(ColorPickerPopup);
