export const svgToURL = (svgString) => {
    const uri = window.btoa(unescape(encodeURIComponent(svgString)));
    return "data:image/svg+xml;base64," + uri;
}


export const getRandomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
}


export const updateSvgColors = (svgString, fillColor, strokeColor, mapScale = 1) => {
    if (!svgString) {
        return svgString;
    }
    // Use DOMParser to parse the SVG string into a document
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");

    // Get all elements that might have fill or stroke attributes
    const elements = svgDoc.querySelectorAll('[fill], [stroke]');

    elements.forEach(element => {
        // Update the fill color if it exists and is not 'none'
        if (element.hasAttribute('fill') && element.getAttribute('fill') !== 'none' && fillColor) {
            element.setAttribute('fill', fillColor);
        }

        // Update the stroke color if it exists and is not 'none'
        if (element.hasAttribute('stroke') && element.getAttribute('stroke') !== 'none' && strokeColor) {
            element.setAttribute('stroke', strokeColor);
        }

        // update svg width and height by mapScale
        // if (element.hasAttribute('width')) {
        //     element.setAttribute('width', `${element.getAttribute('width') * mapScale}`);
        // }
        //
        // if (element.hasAttribute('height')) {
        //     element.setAttribute('height', `${element.getAttribute('height') * mapScale}`);
        // }

    });

    // Serialize the updated SVG document back into a string
    const serializer = new XMLSerializer();
    return serializer.serializeToString(svgDoc);
}

export const getShapeMarkerTransform = (e, targetRef, isEditMode) => {
    if (isEditMode) {
        return;
    }
    const transformString = e.drag.transform; // e.drag.transform is the complete transform string
    const { target } = e;

    // Parse the transform string
    const translateMatch = transformString.match(/translate\((-?\d+(?:\.\d+)?)px, (-?\d+(?:\.\d+)?)px\)/);
    const rotateMatch = transformString.match(/rotate\((-?\d+(?:\.\d+)?)deg\)/);
    const scaleMatch = transformString.match(/scale\((-?\d+(?:\.\d+)?), (-?\d+(?:\.\d+)?)\)/);

    // Default values
    let translateX = 0;
    let translateY = 0;
    let rotate = 0;
    let scaleX = 1;
    let scaleY = 1;

    // Extract values if they exist
    if (translateMatch) {
        translateX = parseFloat(translateMatch[1]);
        translateY = parseFloat(translateMatch[2]);
    }
    if (rotateMatch) {
        rotate = parseFloat(rotateMatch[1]);
    }
    if (scaleMatch) {
        scaleX = parseFloat(scaleMatch[1]);
        scaleY = parseFloat(scaleMatch[2]);
    }

    // Calculate new scale (obtaining scale values from transform string directly)
    const newScaleX = scaleX; // Scale X from the drag transform
    const newScaleY = scaleY; // Scale Y from the drag transform

    // Calculate offsets to maintain center position
    const width = targetRef.offsetWidth * newScaleX; // Get new width based on scaling
    const height = targetRef.offsetHeight * newScaleY; // Get new height based on scaling

    const offsetX = (targetRef.offsetWidth * (newScaleX - scaleX)) / 2;
    const offsetY = (targetRef.offsetHeight * (newScaleY - scaleY)) / 2;

    // Construct the new transform string
    const newTransform = `translate(${offsetX}px, ${offsetY}px) rotate(${rotate}deg) scale(${newScaleX}, ${newScaleY})`;

    // Apply the new transform
    target.style.transform = newTransform;

    // Call the onTransform callback if exists
    return newTransform;
};


export const rgbaToHexAndOpacity = (color) => {
    // Regex for rgba/rgb
    const rgbaRegex = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,?\s*(0|1|0?\.\d+)?\s*\)$/;
    // Regex for hex
    const hexRegex = /^#?([a-fA-F0-9]{6})$/;

    const rgbaResult = rgbaRegex.exec(color);
    const hexResult = hexRegex.exec(color);

    if (rgbaResult) {
        // Parse RGBA components
        const r = parseInt(rgbaResult[1]);
        const g = parseInt(rgbaResult[2]);
        const b = parseInt(rgbaResult[3]);
        const a = rgbaResult[4] !== undefined ? parseFloat(rgbaResult[4]) : 1;

        if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
            throw new Error("RGB values must be in the range 0-255.");
        }

        const toHex = (component) => component.toString(16).padStart(2, '0').toUpperCase();
        const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

        return { hex, opacity: a };
    } else if (hexResult) {
        // Return the hex color with default opacity of 1
        return { hex: `#${hexResult[1].toUpperCase()}`, opacity: 1 };
    } else {
        return { hex: "#95d4f3", opacity: 1 };
    }
}


export const getSvgChildrenDimensions = (svgString) => {
    const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgElement.style.position = 'absolute';
    svgElement.style.visibility = 'hidden';

    svgElement.innerHTML = svgString;

    document.body.appendChild(svgElement);

    // Initialize our bounding box
    const bbox = { x: Infinity, y: Infinity, width: 0, height: 0 };

    // Get all child nodes (e.g., paths, groups)
    const childElements = svgElement.querySelectorAll('*');

    // Calculate bounding box for each child element
    childElements.forEach(element => {
        if (element.tagName.toLowerCase() !== 'svg' && element.tagName.toLowerCase() !== 'rect' && typeof element.getBBox === 'function') {
            const elBbox = element.getBBox();

            // Update our bounding box only if we have a valid bbox
            if (elBbox) {
                console.log("elBbox", elBbox);
                bbox.x = Math.min(bbox.x, elBbox.x);
                bbox.y = Math.min(bbox.y, elBbox.y);
                bbox.width = Math.max(bbox.width, elBbox.x + elBbox.width - bbox.x);
                bbox.height = Math.max(bbox.height, elBbox.y + elBbox.height - bbox.y);
            }
        }
    });
    console.log("bboxbbox-----", bbox);
    // Clean up the temporary element
    document.body.removeChild(svgElement);

    // Return dimensions and aspect ratio
    return {
        width: bbox.width,
        height: bbox.height,
        aspectRatio: bbox.width / bbox.height
    };
}
