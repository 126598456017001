import {
    APPLE_SVG,
    BANK_SVG,
    BOOK_SVG,
    BOOKMARK_SVG,
    BRUSH_SVG, BUS_SVG,
    CARROT_SVG,
    CAT_SVG, CHEF_SVG,
    CHEVRON_SVG,
    CUTLERY_SVG,
    DOLPHIN_SVG,
    ELLIPSE_SVG, FITNESS_SVG,
    FLASK_SVG,
    FLOWER_SVG, FOOTBALL_SVG,
    GLOBE_SVG,
    GREATER_THAN_SVG,
    HAPPY_SVG,
    HAT_SVG,
    HEART_SVG,
    HOSPITAL_SVG,
    ISLAND_SVG,
    LAMP_SVG, LAPTOP_SVG,
    LEMON_SVG,
    LOCATION_SVG,
    MAN_SVG,
    MARKET_SVG,
    MICROSCOPE_SVG,
    MOLECULE_SVG,
    MORTARBOARD_SVG,
    MUSIC_SVG,
    OLD_SVG,
    PALETTE_SVG,
    PAW_SVG,
    PENCIL_SVG,
    PILL_SVG, PLANE_SVG,
    PLUS_SVG, POLICE_SVG,
    QUESTION_SVG,
    RABBIT_SVG,
    RUN_SVG,
    SCHOOL_SVG,
    SHOE_SVG,
    SQUARE_SVG,
    STAR_SVG, STRETCHING_SVG, SUITCASE_SVG,
    SUN_SVG,
    T_SHIRT_SVG,
    TESTTUBE_SVG,
    THEATER_SVG, TRAIN_SVG,
    TREE_SVG,
    TRIANGLE_SVG, TRUCK_SVG, WEIGHTLIFTING_SVG
} from "./shape-svgs";


const TEST_3_SVG_ELLIPSE = `
<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
  <ellipse cx="40" cy="40" rx="38" ry="28" stroke="purple" stroke-width="2" fill="yellow" />
</svg>`

const TEST_4_SVG_POLYGON = `
<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
  <polygon points="40,2 2,78 78,78" stroke="orange" stroke-width="2" fill="pink" />
</svg>`

const TEST_5_SVG_ARROW = `
<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
  <!-- Define the arrowhead marker -->
  <defs>
    <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
      <polygon points="0 0, 10 3.5, 0 7" fill="black" />
    </marker>
  </defs>

  <!-- Draw the line with the arrowhead marker -->
  <line x1="2" y1="40" x2="58" y2="40" stroke="black" stroke-width="2" marker-end="url(#arrowhead)" />
</svg>`

export const shapeCategories = [
   "basic",
   "objects",
   "animals",
   "nature",
    "food",
    "symbols",
    "education",
    "arts",
    "science",
    "people",
    "places",
    "activities",
    "transportation",
    "work",
];



export const allShapes =
    {
        "basic": [
            {
                "id": 1,
                "name": "star",
                "svg": STAR_SVG
            },
            {
                "id": 2,
                "name": "heart",
                "svg": HEART_SVG
            },
            {
                "id": 3,
                "name": "triangle",
                "svg": TRIANGLE_SVG
            },
            {
                "id": 4,
                "name": "bookmark",
                "svg": BOOKMARK_SVG
            },
            {
                "id": 5,
                "name": "ellipse",
                "svg": ELLIPSE_SVG
            },
            {
                "id": 6,
                "name": "square",
                "svg": SQUARE_SVG
            },
            {
                "id": 7,
                "name": "pill",
                "svg": PILL_SVG
            },
            {
                "id": 8,
                "name": "chevron",
                "svg": CHEVRON_SVG
            }
        ],
        "objects": [
            {
                "id": 1,
                "name": "t-shirt",
                "svg": T_SHIRT_SVG
            },
            {
                "id": 2,
                "name": "lamp",
                "svg": LAMP_SVG
            },
            {
                "id": 3,
                "name": "shoe",
                "svg": SHOE_SVG
            },
            {
                "id": 4,
                "name": "hat",
                "svg": HAT_SVG
            }
        ],
        "animals": [
            {
                "id": 1,
                "name": "paw",
                "svg": PAW_SVG
            },
            {
                "id": 2,
                "name": "cat",
                "svg": CAT_SVG
            },
            {
                "id": 3,
                "name": "rabbit",
                "svg": RABBIT_SVG
            },
            {
                "id": 4,
                "name": "dolphin",
                "svg": DOLPHIN_SVG
            }
        ],
        "nature": [
            {
                "id": 1,
                "name": "tree",
                "svg": TREE_SVG
            },
            {
                "id": 2,
                "name": "flower",
                "svg": FLOWER_SVG
            },
            {
                "id": 3,
                "name": "island",
                "svg": ISLAND_SVG
            },
            {
                "id": 4,
                "name": "sun",
                "svg": SUN_SVG
            }
        ],
        "food": [
            {
                "id": 1,
                "name": "apple",
                "svg": APPLE_SVG
            },
            {
                "id": 2,
                "name": "lemon",
                "svg": LEMON_SVG
            },
            {
                "id": 3,
                "name": "carrot",
                "svg": CARROT_SVG
            },
            {
                "id": 4,
                "name": "cutlery",
                "svg": CUTLERY_SVG
            }
        ],
        "symbols": [
            {
                "id": 1,
                "name": "plus",
                "svg": PLUS_SVG
            },
            {
                "id": 2,
                "name": "greater than",
                "svg": GREATER_THAN_SVG
            },
            {
                "id": 3,
                "name": "location",
                "svg": LOCATION_SVG
            },
            {
                "id": 4,
                "name": "question",
                "svg": QUESTION_SVG
            }
        ],
        "education": [
            {
                "id": 1,
                "name": "school",
                "svg": SCHOOL_SVG
            },
            {
                "id": 2,
                "name": "book",
                "svg": BOOK_SVG
            },
            {
                "id": 3,
                "name": "mortarboard",
                "svg": MORTARBOARD_SVG
            },
            {
                "id": 4,
                "name": "pencil",
                "svg": PENCIL_SVG
            }
        ],
        "arts": [
            {
                "id": 1,
                "name": "brush",
                "svg": BRUSH_SVG
            },
            {
                "id": 2,
                "name": "theater",
                "svg": THEATER_SVG
            },
            {
                "id": 3,
                "name": "music",
                "svg": MUSIC_SVG
            },
            {
                "id": 4,
                "name": "palette",
                "svg": PALETTE_SVG
            }
        ],
        "science": [
            {
                "id": 1,
                "name": "molecule",
                "svg": MOLECULE_SVG
            },
            {
                "id": 2,
                "name": "flask",
                "svg": FLASK_SVG
            },
            {
                "id": 3,
                "name": "microscope",
                "svg": MICROSCOPE_SVG
            },
            {
                "id": 4,
                "name": "test tube",
                "svg": TESTTUBE_SVG
            }
        ],
        "people": [
            {
                "id": 1,
                "name": "man",
                "svg": MAN_SVG
            },
            {
                "id": 2,
                "name": "old",
                "svg": OLD_SVG
            },
            {
                "id": 3,
                "name": "happy",
                "svg": HAPPY_SVG
            },
            {
                "id": 4,
                "name": "run",
                "svg": RUN_SVG
            }
        ],
        "places": [
            {
                "id": 1,
                "name": "bank",
                "svg": BANK_SVG
            },
            {
                "id": 2,
                "name": "hospital",
                "svg": HOSPITAL_SVG
            },
            {
                "id": 3,
                "name": "globe",
                "svg": GLOBE_SVG
            },
            {
                "id": 4,
                "name": "market",
                "svg": MARKET_SVG
            }
        ],
        "activities": [
            {
                "id": 1,
                "name": "weightlifting",
                "svg": WEIGHTLIFTING_SVG
            },
            {
                "id": 2,
                "name": "fitness",
                "svg": FITNESS_SVG
            },
            {
                "id": 3,
                "name": "football",
                "svg": FOOTBALL_SVG
            },
            {
                "id": 4,
                "name": "stretching",
                "svg": STRETCHING_SVG
            }
        ],
        "transportation": [
            {
                "id": 1,
                "name": "truck",
                "svg": TRUCK_SVG
            },
            {
                "id": 2,
                "name": "plane",
                "svg": PLANE_SVG
            },
            {
                "id": 3,
                "name": "bus",
                "svg": BUS_SVG
            },
            {
                "id": 4,
                "name": "train",
                "svg": TRAIN_SVG
            }
        ],
        "work": [
            {
                "id": 1,
                "name": "chef",
                "svg": CHEF_SVG
            },
            {
                "id": 2,
                "name": "police",
                "svg": POLICE_SVG
            },
            {
                "id": 3,
                "name": "suitcase",
                "svg": SUITCASE_SVG
            },
            {
                "id": 4,
                "name": "laptop",
                "svg": LAPTOP_SVG
            }
        ]
    }

